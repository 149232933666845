import React, {Fragment} from "react";
import {ThemeProvider} from "styled-components";
import {CremaTheme} from "../theme";
import {ResetCSS} from "../common/assets/css/style";
import "react-responsive-modal/styles.css";
import {ContentWrapper, GlobalStyle,} from "../containers/sassModern.style";
import SEO from "../components/seo";
import Sticky from "react-stickynode";
import {DrawerProvider} from "../common/contexts/DrawerContext";
import Navbar from "../containers/Navbar";
import Footer from "../containers/Footer";
import CremaBanner from "../containers/CremaBanner";
import {graphql, useStaticQuery} from "gatsby";
import Features from "../containers/Features";
import Box from "../elements/Box";
import ContactUs from "../containers/ContactUs";

// function getSize() {
//   return {
//     innerHeight: window.innerHeight,
//     innerWidth: window.innerWidth,
//     outerHeight: window.outerHeight,
//     outerWidth: window.outerWidth,
//   };
// }

// function useWindowSize() {
//   let [windowSize, setWindowSize] = useState(getSize());
//
//   function handleResize() {
//     setWindowSize(getSize());
//   }
//
//   useEffect(() => {
//     window.addEventListener("resize", handleResize);
//
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);
//
//   return windowSize;
// }

export default () => {
  // const size = process.browser && useWindowSize();
  // const innerWidth = process.browser && size.innerWidth;

  const Data = useStaticQuery(graphql`
    query {
      hipsterJson {
        menuItems {
          label
          path
          offset
          staticLink
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={CremaTheme}>
      <Fragment>
        <SEO
          title="Crema React Admin Template."
          description="Crema React is available with Material-UI and Ant Design available with create react app and next.js"
          keywords={[
            "Material Admin Theme",
            "MUI Admin Theme",
            "Admin Theme with TypeScript",
            "Ant Design Admin Theme",
            "React Admin Theme Typescript",
            "React Admin Theme Context API",
            "Redux and Context Api Template",
            "Pure React hooks based Template",
            "Academic Dashboard",
            "Analytics Dashboard",
            "Health Care Dashboard",
            "CRM Dashboard",
            "Crypto Dashboard",
            "Ecommerce Dashboard",
            "Email Application",
            "Calendar Application",
            "TODO/Task Application",
            "Scrum board Application",
            "Chat Application",
            "Profile/Wall Application",
            "Google Map",
            "React Calendar",
            "Firebase",
            "Aws Cognito",
            "Auth0",
            "JWT Auth",
          ]}
        />

        <ResetCSS/>
        <GlobalStyle/>
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar menuItems={Data.hipsterJson.menuItems}/>
            </DrawerProvider>
          </Sticky>
          <CremaBanner/>

          <div className="page-main-content">
            <Features type="Theme"/>
          </div>

          <Box as="section" id="contact_us_section">
            <ContactUs/>
          </Box>

          <Footer/>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
