import React from 'react';
import {BannerMainWrapper, BannerThemeWrapper, BannerWrapper} from "./banner.style";
import Container from "../../common/components/UI/Container";
import Box from "../../elements/Box";
import Fade from "react-reveal/Fade";
import Heading from "../../elements/Heading";
import Image from "../../elements/Image";
import {graphql, useStaticQuery} from "gatsby";

const CremaBanner = () => {
  const data = useStaticQuery(graphql`
    query {
      hipsterJson {
        bannerTheme {
          id
          srcImg
          pathName
          title
        }
      }
    }
  `);
  return (
    <BannerWrapper id="banner_section">
      <Container>
        <BannerMainWrapper>
          <Box className="banner-content">
            <Fade bottom>
              <Heading as="h1" content="Crema Theme"/>
              <h2>
                Available with <span>MUI</span>  and <span>Ant Design + 💅 </span>
              </h2>
              <Heading as="h3"
                       content="Crema is the perfect UI Kit to start your next project. It provides a clean and clear codebase."/>

              <BannerThemeWrapper>
                {data?.hipsterJson?.bannerTheme.map((data, index) => (
                  <Box key={index} className="banner-theme-item">
                    <a
                      className="banner-theme"
                      href={data.pathName}
                      target={data.title==="Figma"?"_blank":"_self"}
                      rel='noreferrer'
                    >
                      <Box className='banner-theme-thumb'>
                        <Image src={data.srcImg} alt="Button Icon"/>
                      </Box>
                      <span className="banner-theme-title">{data.title}</span>
                      {/*{data.subTitle ? <span className="banner-theme-sub-title">{data.subTitle}</span> : null}*/}

                    </a>
                  </Box>
                ))}
              </BannerThemeWrapper>
            </Fade>
          </Box>
        </BannerMainWrapper>
      </Container>
    </BannerWrapper>
  );
};

export default CremaBanner;