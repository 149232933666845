import styled from "styled-components";

import bannerImg from "../../images/header/banner-bg.png";

export const BannerWrapper = styled.section`
  padding: 120px 0 80px;
  background-image: url(${bannerImg});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  overflow: hidden;
  color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 576px) {
    padding: 150px 0 50px;
  }

  @media (min-width: 768px) {
    padding-top: 180px;
  }

  @media (min-width: 1200px) {
    padding-top: 200px;
    padding-bottom: 100px;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .banner-shape {
    z-index: 2;
  }
`;

export const BannerMainWrapper = styled.div`
  text-align: center;

  & .banner-content {
    color: #757575;
    font-size: 16px;
    margin-bottom: 30px;

    @media (min-width: 992px) {
      font-size: 18px;
      margin-bottom: 0;
    }

    & h1 {
      font-size: 32px;
      color: #fff;
      margin-bottom: 6px;
      font-weight: 600;

      @media (min-width: 768px) {
        font-size: 36px;
      }

      @media (min-width: 992px) {
        font-size: 40px;
      }

      @media (min-width: 1200px) {
        font-size: 48px;
      }

      @media (min-width: 1600px) {
        font-size: 54px;
      }
    }

    & h2 {
      font-size: 26px;
      color: #fff;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        font-size: 30px;
      }

      @media (min-width: 992px) {
        margin-bottom: 30px;
      }

      & span {
        color: #fff000;
        //text-decoration: underline;
        font-weight: 600;
      }
    }

    & h3 {
      font-size: 20px;
      color: #fff;
      margin-bottom: 40px;
      font-weight: 400;

      @media (min-width: 768px) {
        font-size: 22px;
      }

      @media (min-width: 992px) {
        font-size: 24px;
        margin-bottom: 60px;
      }
    }
  }
`;

export const BannerThemeWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  & .banner-theme-item {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }

  & .banner-theme {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    border-radius: 1.6rem;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.3);

    & .banner-theme-thumb {
      margin-bottom: 20px;
      background-color: #fff;
      border-radius: 10px;
      width: 60px;
      height: 60px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & img {
      width: 40px;
    }

    & .banner-theme-title {
      color: #fff;
	    max-width: 150px;
    }

    & .banner-theme-sub-title {
      color: #fff;
      font-size: 14px;
    }
  }
`;
